<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar
      flat
      dense
      class="elevation-3"
      :class="{
        'sticky-xs': $vuetify.breakpoint.mdAndDown,
        'sticky-lg': $vuetify.breakpoint.lgAndUp
      }"
      color="secondary"
    >
      <v-btn
        v-if="backRoute.length > 0"
        small
        fab
        dark
        color="info"
        :to="backRoute"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-btn
        v-else
        small
        fab
        dark
        color="info"
        @click="this.$router.back()"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle + " (#" + this.$route.params.id + ")"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark color="success" @click="saveRecord">
        <v-icon dark>save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text
      class="pt-12"
      :class="{ 'pl-0 pr-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
        :class="{
          'loading-visible': this.loading,
          'loading-hidden': !this.loading
        }"
      ></v-progress-circular>

      <v-tabs v-model="tabs" show-arrows>
          <v-tab>
            Osnovni podaci
          </v-tab>
          <v-tab>
            Stavke
          </v-tab>
          <v-tab>
            Ostali podaci
          </v-tab>
          <v-tab>
            E-Račun
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item
            :eager="true"
            transition="scroll-y-transition"
            reverse-transition="scroll-y-transition"
          >
          <v-container fluid>
            <v-row dense align="start" justify="start">
              <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Broj računa"
              :model.sync="form.invoice_num"
              disabled
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <InvoiceTypeInput
              label="Vrsta računa"
              :model.sync="form.type"
              :disabled="this.$route.params.id>-1"
            ></InvoiceTypeInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Poziv na broj"
              :model.sync="form.irn"
              :disabled="$route.params.id>-1"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Vrijeme izdavanja"
              :model.sync="form.created"
              :disabled="$route.params.id>-1"
              :initialValue="$route.params.id>-1 ? '' : getCurrentTime()"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6">
            <HtmlInput
              label="Platitelj"
              :model.sync="form.recipient"
            ></HtmlInput>
          </v-col>
          <v-col cols="12" sm="6">
            <HtmlInput
              label="Adresa"
              :model.sync="form.address"
            ></HtmlInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <CurrencyInput
              label="Valuta"
              :model.sync="form.currency"
            ></CurrencyInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Iznos"
              :model.sync="form.amount"
              :disabled="$route.params.id>-1"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="PDV (25%)"
              :model.sync="form.vat"
              :disabled="$route.params.id>-1"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Ukupni iznos"
              :model.sync="form.amount_total"
              :disabled="$route.params.id>-1"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <DateInput
              label="Datum usluge od"
              :model.sync="form.period_from"
            ></DateInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <DateInput
              label="Datum usluge do"
              :model.sync="form.period_to"
            ></DateInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <DateInput
              label="Rok plaćanja"
              :model.sync="form.due_date"
            ></DateInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <DateInput
              label="Datum plaćanja"
              :model.sync="form.paid"
              disabled
            ></DateInput>
          </v-col>
           <v-col cols="12" sm="6" md="3">
             <InvoiceStateInput
              label="Status računa"
              :model.sync="form.state"
              disabled
            ></InvoiceStateInput>
           </v-col>
            <v-col cols="12" sm="6" md="3">
            <QuoteInput
              label="Ponuda"
              :model.sync="form.quote"
            ></QuoteInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-switch
              label="OIB/VAT"
              v-model="form.oib_vat"
              :true-value="'1'"
              :false-value="'0'"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="OIB/VAT ID"
              :model.sync="form.vat_id"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <InvoiceInput
              label="Storno račun"
              :model.sync="form.storno_invoice"
            ></InvoiceInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <InvoiceInput
              label="Račun za predujam"
              :model.sync="form.prepayment_invoice"
            ></InvoiceInput>
          </v-col>
          <v-col cols="12" sm="6">
            <HtmlInput
              label="Opis"
              :model.sync="form.description"
            ></HtmlInput>
          </v-col>
            </v-row>
          </v-container>
          </v-tab-item>
          <v-tab-item
            :eager="true"
            transition="scroll-y-transition"
            reverse-transition="scroll-y-transition"
          >
          <v-container fluid>
            <v-row dense align="start" justify="start">
              <v-col cols="12">
            <InvoiceItemsInput label="Stavke računa" :model.sync="form.items">
            </InvoiceItemsInput>
          </v-col>
            </v-row>
          </v-container>
          </v-tab-item>
          <v-tab-item
            :eager="true"
            transition="scroll-y-transition"
            reverse-transition="scroll-y-transition"
          >
          <v-container fluid>
            <v-row dense align="start" justify="start">
              <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Vrijeme slanja"
              :model.sync="form.sent"
              disabled
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <LangInput
              label="Jezik računa"
              :model.sync="form.lang"
            ></LangInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Broj narudžbe"
              :model.sync="form.order_num"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <DateInput
              label="Datum narudžbe"
              :model.sync="form.order_date"
            ></DateInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <EventInput
              label="Događaj"
              :model.sync="form.event"
            ></EventInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="ID prijave na događaj"
              :model.sync="form.event_registration"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <UserInput
              label="Osoba"
              :model.sync="form.user"
            ></UserInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <CompanyInput
              label="Poduzeće"
              :model.sync="form.company"
            ></CompanyInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <UnitInput
              label="Org. jedinica"
              :model.sync="form.org_unit"
              :parentCompany="
                form.company != null ? Number(form.company) : 0
              "
            >
            </UnitInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-switch
              label="Bez detalja"
              v-model="form.no_details"
              :true-value="1"
              :false-value="0"
            ></v-switch>
          </v-col>

          
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="IBAN"
              :model.sync="form.iban"
            ></TextInput>
          </v-col>
        
           <v-col cols="12" sm="6" md="3">
            <HeaderFooterInput
              label="Zaglavlje"
              :model.sync="form.header"
            ></HeaderFooterInput>
          </v-col>
           <v-col cols="12" sm="6" md="3">
            <HeaderFooterInput
              label="Podnožje"
              :model.sync="form.footer"
            ></HeaderFooterInput>
          </v-col>
           <v-col cols="12" sm="6" md="3">
            <HeaderFooterInput
              label="Završni tekst"
              :model.sync="form.end_text"
            ></HeaderFooterInput>
          </v-col>
            </v-row>
          </v-container>
          </v-tab-item>
          <v-tab-item
            :eager="true"
            transition="scroll-y-transition"
            reverse-transition="scroll-y-transition"
          >
          <v-container fluid>
            <v-row dense align="start" justify="start">
            <v-col cols="12" sm="6" md="3">
            <TextInput
              label="ID e-Računa"
              :model.sync="form.eid"
              disabled
            ></TextInput>
          </v-col>
            <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Status računa"
              :model.sync="form.estate"
              disabled
            ></TextInput>
          </v-col>
            <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Status procesa"
              :model.sync="form.pstate"
              disabled
            ></TextInput>
          </v-col>
            <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Dodatni status"
              :model.sync="form.astate"
              disabled
            ></TextInput>
          </v-col>
          <v-col cols="12">
            <TextInput
              label="Poruka o grešci"
              :model.sync="form.emsg"
              disabled
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Oznaka države kupca"
              :model.sync="form.country"
            ></TextInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <TextInput
              label="Poslovna jedinica kupca"
              :model.sync="form.eunit"
            ></TextInput>
            <v-btn color="success darken-1" small dark @click="dialogEUnit = true"
            ><v-icon>format_list_bulleted</v-icon></v-btn>
          </v-col>
            </v-row>
          </v-container>
          </v-tab-item>
        </v-tabs-items>
      <v-snackbar
        v-model="snackbar"
        right
        :timeout="3000"
        :color="this.snackbarType === 0 ? 'success' : 'error'"
        >{{ this.message }}
      </v-snackbar>
    </v-card-text>
    <v-dialog v-model="dialogEUnit" max-width="600">
      <v-card>
        <v-card-title class="title" primary-title
          >Odabir poslovne jedinice</v-card-title
        >
        <v-card-text
          >Odaberite poslovnu jedinicu iz liste preuzete s e-račun servisa
          <InteractiveTable
              ref="eunitList"
              class="xl-table mt-2"
              :headers="[
                { text: 'ID', value: 'id' },
                { text: 'Naziv', value: 'name' },
                { text: 'Adresa', value: 'address' }
              ]"
              :apiLoad="
                'invoice/invoiceunits.php?invoice='.concat(
                  this.$route.params.id
                )
              "
              :actions="[
                {
                  id: '1',
                  name: 'Odaberi',
                  eventCall: 'editRow',
                  icon: 'task_alt',
                  color: 'secondary'
                }
              ]"

              defaultFilter="name"
              :model.sync="eunits"
              @editRow="selectEUnit"
              defaultSort="id"
            ></InteractiveTable>
          </v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success darken-1" text @click="dialogEUnit = false"
            >Zatvori</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import DateInput from "@/components/DateInput.vue";
//import ToggleInput from "@/components/ToggleInput.vue";
import TextInput from "@/components/TextInput.vue";
import EventInput from "@/components/EventInput.vue";
import CompanyInput from "@/components/CompanyInput.vue";
import UnitInput from "@/components/UnitInput.vue";
import InvoiceItemsInput from "@/components/InvoiceItemsInput.vue";
import HtmlInput from "@/components/HtmlInput.vue";
import InvoiceTypeInput from "@/components/InvoiceTypeInput.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import InvoiceStateInput from "@/components/InvoiceStateInput.vue";
import LangInput from "@/components/LangInput.vue";
import UserInput from "@/components/UserInput.vue";
import QuoteInput from "@/components/QuoteInput.vue";
import InvoiceInput from "@/components/InvoiceInput.vue";
import HeaderFooterInput from "@/components/HeaderFooterInput.vue";
import InteractiveTable from "../components/InteractiveTable";
import moment from 'moment';

export default {
  components: {
    DateInput,
    // ToggleInput,
    TextInput,
    EventInput,
    CompanyInput,
    UnitInput,
    UserInput,
    InvoiceItemsInput,
    HtmlInput,
    InvoiceTypeInput,
    InvoiceStateInput,
    CurrencyInput,
    LangInput,
    QuoteInput,
    InvoiceInput,
    HeaderFooterInput,
    InteractiveTable
},
  props: {
    backRoute: String,
    listRefreshAction: String
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackbarType: 0,
      message: "",
      tabs:0,
      form: {
        invoice_num:null,
        recipient:null,
        irn:null,
        amount:null,
        vat:null,
        amount_total:null,
        type:null,
        state:null,
        created:null,
        period_from:null,
        period_to:null,
        sent:null,
        currency:moment()>=moment('2023-01-01') ? "EUR" : "HRK",
        due_date:null,
        lang:null,
        order_num:null,
        order_date:null,
        user:null,
        company:null,
        org_unit:null,
        event:null,
        event_registration:null,
        quote:null,
        no_details:null,
        storno_invoice:null,
        prepayment_invoice:null,
        header:null,
        footer:null,
        address:null,
        vat_id:null,
        oib_vat:null,
        paid:null,
        end_text:null,
        iban:null,
        description:null,
        estate:null,
        pstate:null,
        astate:null,
        emsg:null,
        country:null,
        eid:null,
        eunit:null,
        items: []
      },
      events: [],
      dialogEUnit: false,
      eunits:[]
    };
  },
  methods: {
    reload() {
      this.loading = true;
      var me = this;
      this.$http
        .get("/invoice/invoice.php?format=json", {
          params: {
            id: this.$router.currentRoute.params.id
          }
        })
        .then(response => {
          me.loading = false;
          if(response.data!=null && response.data!="")
            me.form = response.data;
        })
        .catch(error => {
          me.loading = false;
        });
    },
    saveRecord() {
      this.loading = true;
      if(this.$router.currentRoute.params.id<0) {
        // insert
        this.$http
        .post("/invoice/invoice.php", {
          params: {
            invoice: {
              form: this.form
            }
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Podaci su uspješno spremljeni";
            this.$store.dispatch(this.listRefreshAction);
          } else {
            this.snackbarType = 1;
            this.message = "Greška u spremanju podataka";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u spremanju podataka";
          this.snackbar = true;
        });
      } else {
        // update
      this.$http
        .put("/invoice/invoice.php", {
          params: {
            invoice: {
              id: this.$router.currentRoute.params.id,
              form: this.form
            }
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Podaci su uspješno spremljeni";
            this.$store.dispatch(this.listRefreshAction);
          } else {
            this.snackbarType = 1;
            this.message = "Greška u spremanju podataka";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u spremanju podataka";
          this.snackbar = true;
        });
      }
    },
    getCurrentTime() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    selectEUnit(sel) {
      if(isset(sel.id))
        this.form.eunit = sel.id;
      else
        this.form.eunit = sel.name;
      this.dialogEUnit = false;
    },
  },
  watch: {
    'form.items': {
      handler() {
        // recalculate amount
        if(this.form.items.rows!=null && this.form.items.rows.length>0) {
          var _amount = 0;
          var _vat = 0;
          var _amount_total = 0;
          this.form.items.rows.forEach(element => {
            _amount += parseFloat(element.amount);
            _vat += parseFloat(element.vat);
            _amount_total += parseFloat(element.amount_total);
          });
          this.form.amount = _amount.toString();
          this.form.vat = _vat.toString();
          this.form.amount_total = _amount_total.toString();
        } else {
          this.form.amount = 0;
          this.form.vat = 0;
          this.form.amount_total = 0;
        }
      },
      deep: true
    },
  },
  mounted() {
    this.reload();
  }
};
</script>
