<template>
  <v-select
    v-model="fieldModelValue"
    :items="currency_types"
    item-text="name"
    item-value="id"
    menu-props="auto"
    :label="label"
    prepend-icon="euro"
    return-object
    :readonly="disabled"
  ></v-select>
</template>
<script>
export default {
  props: {
    label: String,
    model: String,
    disabled: Boolean
  },
  data() {
    return {
      currency_types: [
        { id: 'EUR+HRK', name: "EUR+HRK" },
        { id: 'EUR', name: "EUR" },
        { id: 'HRK+EUR', name: "HRK+EUR" },
        { id: 'HRK', name: "HRK" }
      ],
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        var modelprop = this.model;
        var filtered = this.currency_types.filter(function(c) {
          return modelprop == c.id;
        });
        return filtered ? filtered[0] : null;
      },
      set(newValue) {
        this.$emit("update:model", newValue.id);
      }
    }
  }
};
</script>
