<template>
  <v-scale-transition>
    <InvoiceEditor
      backRoute="/invoicelist"
      listRefreshAction="invoice_list_needs_refresh"
    >
    </InvoiceEditor>
  </v-scale-transition>
</template>

<script>
import InvoiceEditor from "@/components/InvoiceEditor.vue";

export default {
  data() {
    return {};
  },
  components: {
    InvoiceEditor
  }
};
</script>
