<template>
  <v-select
    v-model="fieldModelValue"
    :items="invoice_types"
    item-text="name"
    item-value="id"
    menu-props="auto"
    :label="label"
    prepend-icon="receipt"
    return-object
    :readonly="disabled"
  ></v-select>
</template>
<script>
export default {
  props: {
    label: String,
    model: {String,Number},
    disabled: Boolean
  },
  data() {
    return {
      invoice_types: [
        { id: 1, name: "Ind. članarina - uplatnica" },
        { id: 3, name: "Ind. članarina - poduzeće po uplatnici" },
        { id: 12, name: "Ind. članarina - poduzeće po ponudi" },
        { id: 14, name: "Kolektivna članarina" },
        { id: 15, name: "Međunarodna individualna članarina" },
        { id: 16, name: "Međunarodna kolektivna članarina" },
        { id: 80, name: "Predujam za kotizaciju" },
        { id: 90, name: "Kotizacija" },
        { id: 110, name: "Sponzorski račun" },
        { id: 100, name: "Predujam za sponzorski račun" },
      ],
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        var modelprop = this.model;
        var filtered = this.invoice_types.filter(function(c) {
          return modelprop == c.id;
        });
        return filtered ? filtered[0] : null;
      },
      set(newValue) {
        this.$emit("update:model", newValue.id);
      }
    }
  }
};
</script>
