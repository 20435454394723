<template>
  <v-select
    v-model="fieldModelValue"
    :items="invoice_states"
    item-text="name"
    item-value="id"
    menu-props="auto"
    :label="label"
    return-object
    :readonly="disabled"
  ></v-select>
</template>
<script>
export default {
  props: {
    label: String,
    model: String,
    disabled: Boolean
  },
  data() {
    return {
      invoice_states: [
        { id: 0, name: "KREIRAN" },
        { id: 1, name: "POSLAN" },
        { id: 2, name: "PRIHVAĆEN" },
        { id: 3, name: "STORNIRAN" },
      ],
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        var modelprop = this.model;
        var filtered = this.invoice_states.filter(function(c) {
          return modelprop == c.id;
        });
        return filtered ? filtered[0] : null;
      },
      set(newValue) {
        this.$emit("update:model", newValue.id);
      }
    }
  }
};
</script>
