<template>
  <div>
    <div>{{label}}</div>
    <div class="editor_outline">
      <div class="editor_buttons">
      <v-btn icon small @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor==null ? false : editor.isActive('bold') }">
       <v-icon>format_bold</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor==null ? false : editor.isActive('italic') }">
      <v-icon>format_italic</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor==null ? false : editor.isActive('underline') }">
      <v-icon>format_underline</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor==null ? false : editor.isActive('heading', { level: 1 }) }">
      <h2>H1</h2>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor==null ? false : editor.isActive('heading', { level: 2 }) }">
      <h2>H2</h2>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor==null ? false : editor.isActive('heading', { level: 3 }) }">
      <h2>H3</h2>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor==null ? false : editor.isActive({ textAlign: 'left' }) }">
      <v-icon>format_align_left</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor==null ? false : editor.isActive({ textAlign: 'center' }) }">
      <v-icon>format_align_center</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor==null ? false : editor.isActive({ textAlign: 'right' }) }">
      <v-icon>format_align_right</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor==null ? false : editor.isActive('bulletList') }">
      <v-icon>format_list_bulleted</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().setHorizontalRule().run()">
      <v-icon>horizontal_rule</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().setHardBreak().run()">
      <v-icon>keyboard_return</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().undo().run()">
      <v-icon>undo</v-icon>
    </v-btn>
    <v-btn icon small @click="editor.chain().focus().redo().run()">
      <v-icon>redo</v-icon>
    </v-btn>
      </div>
   <editor-content class="editor" :editor="editor" />
   </div>
  </div>
</template>
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'

export default {
  components: {
    EditorContent,
  },
  props: {
    label: String,
    model: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor:null,
    };
  },
  watch: {
    model(val) {
      const isSame = this.editor.getHTML() === val
      if (isSame) {
        return
      }
      this.editor.commands.setContent(val, false);
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.model,
      extensions: [
        StarterKit,
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph", "image"],
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit('update:model', this.editor.getHTML())
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy()
  }
};
</script>

<style>
.is-active
{
  background-color: lightgray !important;
}

.editor_outline
{
  border: solid 1px #cccccc;
}

.editor_buttons
{
  background-color: rgb(176, 224, 252);
}

.editor
{
  padding: 10px;
  font-size: 16px;
}

.ProseMirror:focus {
  outline: none;
}
</style>
