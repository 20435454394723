<template>
  <v-autocomplete
    v-model="fieldModelValue"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    class="mx-4"
    cache-items
    hide-no-data
    hide-details
    :label="label"
    item-value="id"
    item-text="name"
    return-object
  ></v-autocomplete>
</template>
<script>
export default {
  props: {
    label: String,
    model: String
  },
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      fieldModelValue: null,
      initValue: false
    };
  },
  watch: {
    search(val) {
      val &&
        (!this.fieldModelValue || val !== this.fieldModelValue.name) &&
        this.querySelections("name", val);
    },
    fieldModelValue(val) {
      this.$emit("update:model", val.id);
    },
    model(val) {
      if (val) {
        this.initValue = true;
        this.querySelections("id", val);
      }
    }
  },
  methods: {
    querySelections(f, v) {
      this.loading = true;
      var me = this;
      this.$http
        .get("/headerfooter/headerfooters.php", {
          params: {
            num: v,
            filter: f
          }
        })
        .then(response => {
          var ritems = response.data;
          me.items = ritems;
          if (me.initValue) {
            if (me.items && me.items.length > 0)
              if (
                me.fieldModelValue == null ||
                me.fieldModelValue.id != me.items[0].id
              )
                me.fieldModelValue = me.items[0];
            me.initValue = false;
          }
          me.loading = false;
        })
        .catch(error => {
          //reject(error);
          me.loading = false;
        });
    }
  }
};
</script>
