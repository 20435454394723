<template>
  <div>
    <v-btn small dark class="mx-1" color="accent" @click="addRow">
      Stavke računa<v-icon dark>add</v-icon>
    </v-btn>
    <InteractiveTable
      ref="Invoice1ItemsList"
      class="i-table"
      :headers="[
        { text: 'ID', value: 'id' },
        { text: 'RB', value: 'num' },
        { text: 'Naziv', value: 'name' },
        { text: 'Količina', value: 'quantity' },
        { text: 'Iznos', value: 'amount' },
        { text: 'PDV (25%)', value: 'vat' },
        { text: 'Ukupni iznos', value: 'amount_total' }
      ]"
      :apiLoad="
        'invoice/invoiceitems.php?invoice='.concat(
          this.$router.currentRoute.params.id
        )
      "
      :actions="[
        {
          id: '1',
          name: 'Uredi',
          eventCall: 'editRow',
          icon: 'edit',
          color: 'secondary'
        },
        {
          id: '2',
          name: 'Briši',
          eventCall: 'deleteRow',
          icon: 'delete_forever',
          color: 'error'
        }
      ]"
      defaultFilter="name"
      :model.sync="fieldModelValue.rows"
      @editRow="editRow"
      @deleteRow="openDeleteDialog"
      defaultSort="num"
    ></InteractiveTable>
    <v-dialog v-model="dialogEdit" width="500" :eager="true">
      <v-card>
        <v-card-title>Uređivanje stavke računa</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <TextInput
                  label="RB"
                  :model.sync="formData['num']"
                ></TextInput>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <TextInput
                  label="Naziv"
                  :model.sync="formData['name']"
                ></TextInput>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <TextInput
                  label="Količina"
                  :model.sync="formData['quantity']"
                ></TextInput>
              </v-col>
              <v-col cols="6">
                <TextInput
                  label="Iznos"
                  :model.sync="formData['amount']"
                ></TextInput>
              </v-col>
              <v-col cols="6">
                <TextInput
                  label="PDV (25%)"
                  :model.sync="formData['vat']"
                ></TextInput>
              </v-col>
              <v-col cols="6">
                <TextInput
                  label="Ukupni iznos"
                  :model.sync="formData['amount_total']"
                ></TextInput>
              </v-col>
              <v-col cols="6">
                <TextInput
                  label="Sudionik"
                  :model.sync="formData['participant']"
                ></TextInput>
              </v-col>
              <v-col cols="6">
                <TextInput
                  label="Korisnik"
                  :model.sync="formData['user']"
                ></TextInput>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            dark
            rounded
            class="mx-1"
            color="primary"
            @click="calcVAT"
            >IZRAČUNAJ</v-btn
          >
          <v-btn
            v-if="editMode == 1"
            small
            dark
            rounded
            class="mx-1"
            color="secondary"
            @click="addRowToTable"
            >DODAJ</v-btn
          >
          <v-btn
            v-if="editMode == 2"
            small
            dark
            rounded
            class="mx-1"
            color="secondary"
            @click="editRowInTable"
            >SPREMI</v-btn
          >
          <v-btn
            small
            dark
            rounded
            class="mx-1"
            color="grey"
            @click="dialogEdit = false"
            >OTKAŽI</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" width="500">
      <v-card>
        <v-card-title>Upozorenje</v-card-title>
        <v-card-text>
          Jeste li sigurni da želite izbrisati stavku računa?
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            dark
            rounded
            class="mx-1"
            color="error"
            @click="deleteRow"
            >BRIŠI</v-btn
          >
          <v-btn
            small
            dark
            rounded
            class="mx-1"
            color="grey"
            @click="dialogDelete = false"
            >OTKAŽI</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InteractiveTable from "../components/InteractiveTable";
import TextInput from "../components/TextInput";

export default {
  props: {
    label: String,
    model: {Object,Array}
  },
  components: {
    InteractiveTable,
    TextInput
  },
  data() {
    return {
      loading: false,
      search: null,
      selected: null,
      editMode: 1,
      dialogEdit: false,
      formData: {
        id: null,
        invoice: this.$router.currentRoute.params.id,
        num: null,
        name: null,
        quantity: null,
        amount: null,
        vat: null,
        amount_total: null,
        participant: null,
        user: null
      },
      editingRow: null,
      fieldModelValue: {
        rows: [],
        deletedRows: []
      },
      dialogDelete: false,
      nextId: -1
    };
  },
  watch: {
    fieldModelValue: {
      handler() {
        this.$emit("update:model", this.fieldModelValue);
      },
      deep: true
    },
    model(val) {
      if (val) this.fieldModelValue = val;
    }
  },
  methods: {
    addRow() {
      this.editMode = 1;
      this.dialogEdit = true;
    },
    calcVAT() {
      this.$set(this.formData,"vat", String(0.25*this.formData.amount*this.formData.quantity));
      this.$set(this.formData,"amount_total", String(1.25*this.formData.amount*this.formData.quantity));
    },
    editRow(sel) {
      this.editMode = 2;
      this.formData.id = sel.id;
      this.formData.num = sel.num;
      this.formData.name = sel.name;
      this.formData.quantity = sel.quantity;
      this.formData.amount = sel.amount;
      this.formData.vat = sel.vat;
      this.formData.amount_total = sel.amount_total;
      this.formData.participant = sel.participant;
      this.formData.user = sel.user;
      this.editingRow = sel;
      this.dialogEdit = true;
    },
    deleteRow() {
      // add to deleted list
      if (this.editingRow.id != -1)
        this.fieldModelValue.deletedRows.push(this.editingRow);
      for (var i = 0; i < this.fieldModelValue.rows.length; i++) {
        if (this.fieldModelValue.rows[i].id == this.editingRow.id) {
          this.fieldModelValue.rows.splice(i, 1);
          break;
        }
      }
      this.dialogDelete = false;
    },
    openDeleteDialog(sel) {
      this.editingRow = sel;
      this.dialogDelete = true;
    },
    addRowToTable() {
      if (this.formData.num && this.formData.name && this.formData.quantity && this.formData.amount) {
        var newrow = {
          id: this.nextId--,
          invoice: this.formData.invoice,
          num: this.formData.num,
          name: this.formData.name,
          quantity: this.formData.quantity,
          amount: this.formData.amount,
          vat: this.formData.vat,
          amount_total: this.formData.amount_total,
          participant: this.formData.participant,
          user: this.formData.user,
          action: "A"
        };
        if(!this.fieldModelValue.rows)
          this.fieldModelValue.rows = [];
        this.fieldModelValue.rows.push(newrow);
        this.dialogEdit = false;
      }
    },
    editRowInTable() {
      if (this.formData.num && this.formData.name && this.formData.quantity && this.formData.amount) {
        this.editingRow.num = this.formData.num;
        this.editingRow.name = this.formData.name;
        this.editingRow.quantity = this.formData.quantity;
        this.editingRow.amount = this.formData.amount;
        this.editingRow.vat = this.formData.vat;
        this.editingRow.amount_total = this.formData.amount_total;
        this.editingRow.participant = this.formData.participant;
        this.editingRow.user = this.formData.user;
        if (this.editingRow.id > 0) this.editingRow.action = "E";
        this.dialogEdit = false;
      }
    }
  }
};
</script>

<style scoped>
.i-table .v-data-table__wrapper {
  overflow-y: auto !important;
  height: calc(100vh - 100px) !important;
}
</style>
